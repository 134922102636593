<template>
  <mobile-screen :header="true" screen-class="levels-screen icon-app1 gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="level-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_levels-help-online',
          params: $route.params
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("level", "level", "levels") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
          <template v-if="mode === 'selection'">
            <button @click="setMode(null)">
              <icon icon="#cx-hea1-edit-done" />
            </button>
          </template>
          <template v-else>
            <router-link
              v-if="can('MANAGE_LEVELS', 'create')"
              :to="{
                name: 'r_add-level',
                params: $route.params
              }"
            >
              <icon icon="#cx-hea1-add" />
            </router-link>
          </template>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper level-search" v-if="search">
        <div class="search-field">
          <form @submit.prevent="submitSearch" novalidate>
            <input
              type="text"
              ref="searchForm"
              @keyup="searchQuery = $event.target.value"
              :placeholder="displayLabelName('level', 'level', 'search')"
            />
            <div class="icon search">
              <icon icon="#cx-app1-search-16x16" width="16" height="16" />
            </div>
          </form>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>

    <!-- levels search section -->
    <div class="scroll"></div>
    <template v-if="listOfLevels && listOfLevels.length && showSearchResults">
      <ul class="clebex-item-section pill">
        <div class="clebex-item-section-label-inner">
          <div class="clebex-label-content-wrapper">
            <div class="label">
              {{ displayLabelName("level", "level", "search-results") }}
            </div>
            <span class="follow-up-icons">
              <button
                class="follow-up-icon-item"
                @click="
                  searchQuery = '';
                  $refs.searchForm.value = '';
                  showSearchResults = false;
                  openLevels();
                "
              >
                <!-- TODO fali ikonice -->
                <svg-icon icon="outside"></svg-icon>
              </button>
            </span>
          </div>
        </div>
        <template v-if="mode === 'selection'">
          <li
            class="clebex-item-section-item"
            v-for="level in listOfLevels"
            :key="level.id"
          >
            <div class="clebex-item-content-wrapper">
              <div class="checkbox tiny block alt">
                <input
                  type="checkbox"
                  :id="`fav-level${level.id}`"
                  name="fav-level"
                  :selected="level.id === level"
                  :value="level.id"
                  @change="selectLevel(level.id)"
                  :disabled="!canItem(lvl, 'delete')"
                />
                <label :for="`fav-level${level.id}`">
                  <icon icon="#cx-app1-checkmark" />
                  <dl class="clebex-item-dl no-left-padding inner-text">
                    <dt class="clebex-item-dt">
                      <div
                        class="clebex-item-parent-list"
                        v-if="level.all_parents && level.all_parents.length"
                      >
                        <span
                          class="parent-name"
                          v-for="parent in level.all_parents"
                          :key="parent.id"
                        >
                          {{ parent.name }}
                        </span>
                      </div>
                    </dt>
                    <dd class="clebex-item-dd">
                      <div class="link">
                        {{ level.name }}
                        <icon
                          icon="#cx-app1-arrow-right-12x12"
                          width="12"
                          height="12"
                        />
                      </div>
                    </dd>
                  </dl>
                </label>
              </div>
            </div>
          </li>
        </template>
        <template v-else>
          <li
            class="clebex-item-section-item"
            v-for="level in listOfLevels"
            :key="level.id"
            :class="{ disabled: !canItem(level, 'update') }"
          >
            <div class="clebex-item-content-wrapper">
              <span class="follow-up-icons">
                <span
                  class="follow-up-icon-item"
                  :class="{
                    default: level.is_default,
                    favorite: level.is_favorite && !level.is_default
                  }"
                >
                  <icon
                    v-if="level.is_default"
                    icon="#cx-app1-favourite-default"
                  />
                  <icon
                    v-else-if="level.is_favorite"
                    icon="#cx-app1-favourite-fill"
                  />
                  <icon v-else icon="#cx-app1-favourite" />
                </span>
              </span>
              <dl class="clebex-item-dl">
                <dt class="clebex-item-dt">
                  <div
                    class="clebex-item-parent-list"
                    v-if="level.all_parents && level.all_parents.length"
                  >
                    <p
                      class="parent-name"
                      v-for="parent in level.all_parents"
                      :key="parent.id"
                    >
                      {{ parent.name }}
                    </p>
                  </div>
                </dt>
                <dd class="clebex-item-dd">
                  <button @click="editLevel(level.id)">
                    {{ level.name }}
                    <icon
                      icon="#cx-app1-arrow-right-12x12"
                      width="12"
                      height="12"
                    />
                  </button>
                </dd>
              </dl>
            </div>
          </li>
        </template>
      </ul>
    </template>

    <!-- levels favorites section -->
    <template v-else-if="favoriteLevels && favoritesActive">
      <ul
        class="clebex-item-section pill"
        v-if="favoriteLevels && favoriteLevels && favoriteLevels.length"
      >
        <li class="clebex-item-section-label-inner">
          <div class="clebex-label-content-wrapper">
            <div class="label">
              {{ displayLabelName("level", "level", "favorite-levels") }}
            </div>
            <span class="follow-up-icons">
              <button class="follow-up-icon-item" @click="openLevels">
                <icon icon="#cx-app1-favourite-fill"></icon>
              </button>
            </span>
          </div>
        </li>
        <template v-if="mode === 'selection'">
          <li
            class="clebex-item-section-item"
            v-for="level in favoriteLevels"
            :key="level.id"
          >
            <div class="clebex-item-content-wrapper checkbox tiny block alt">
              <input
                type="checkbox"
                :id="`fav-level${level.id}`"
                name="fav-level"
                :selected="level.id === level"
                :value="level.id"
                @change="selectLevel(level.id)"
                :disabled="!canItem(level, 'delete')"
              />
              <label :for="`fav-level${level.id}`">
                <icon icon="#cx-app1-checkmark" />
                <dl class="clebex-item-dl inner-text no-left-padding">
                  <dt class="clebex-item-dt">
                    <div
                      class="clebex-item-parent-list"
                      v-if="level.all_parents && level.all_parents.length"
                    >
                      <span
                        class="parent-name"
                        v-for="parent in level.all_parents"
                        :key="parent.id"
                      >
                        {{ parent.name }}
                      </span>
                    </div>
                  </dt>
                  <dd class="clebex-item-dd">
                    <div class="link">
                      {{ level.name }}
                      <icon
                        icon="#cx-app1-arrow-right-12x12"
                        width="12"
                        height="12"
                      />
                    </div>
                  </dd>
                </dl>
              </label>
            </div>
          </li>
        </template>
        <template v-else>
          <li
            class="clebex-item-section-item"
            v-for="level in favoriteLevels"
            :key="level.id"
            :class="{ disabled: !canItem(level, 'update') }"
          >
            <div class="clebex-item-content-wrapper">
              <span class="follow-up-icons">
                <span
                  class="follow-up-icon-item"
                  :class="{
                    default: level.is_default,
                    favorite: level.is_favorite && !level.is_default
                  }"
                >
                  <icon
                    v-if="level.is_default"
                    icon="#cx-app1-favourite-default"
                  />
                  <icon
                    v-else-if="level.is_favorite"
                    icon="#cx-app1-favourite-fill"
                  />
                  <icon v-else icon="#cx-app1-favourite" />
                </span>
              </span>
              <dl class="clebex-item-dl no-left-padding">
                <dt class="clebex-item-dt">
                  <div
                    class="clebex-item-parent-list"
                    v-if="level.all_parents && level.all_parents.length"
                  >
                    <p
                      class="parent-name"
                      v-for="parent in level.all_parents"
                      :key="parent.id"
                    >
                      {{ parent.name }}
                    </p>
                  </div>
                </dt>
                <dd class="clebex-item-dd">
                  <button @click="editLevel(level)">
                    {{ level.name }}
                    <icon
                      icon="#cx-app1-arrow-right-12x12"
                      width="12"
                      height="12"
                    />
                  </button>
                </dd>
              </dl>
            </div>
          </li>
        </template>
      </ul>
    </template>

    <template v-else>
      <!-- levels section parents -->
      <ul class="clebex-item-section pill">
        <li class="clebex-item-section-label-inner">
          <div class="clebex-label-content-wrapper">
            <div class="label">
              {{ displayLabelName("level", "level", "levels") }}
            </div>
            <span class="follow-up-icons">
              <button class="follow-up-icon-item" @click="openFavorites">
                <icon icon="#cx-app1-favourite" />
              </button>
            </span>
          </div>
        </li>
        <template v-if="parentLevels && parentLevels.length && routeParam">
          <li
            class="clebex-item-section-item"
            v-for="(parent, idx) in parentLevels"
            :key="parent.id"
            :class="{ disabled: !canItem(parent, 'update') }"
          >
            <div class="clebex-item-content-wrapper">
              <span class="follow-up-icons">
                <button
                  class="follow-up-icon-item"
                  :class="{
                    default: parent.is_default,
                    favorite: parent.is_favorite && !parent.is_default
                  }"
                  :disabled="!canItem(parent, 'update')"
                  @click.stop="
                    !parent.is_default
                      ? parent.is_favorite
                        ? unsetFavorite(parent.id)
                        : setFavorite(parent.id)
                      : false
                  "
                >
                  <icon
                    v-if="parent.is_default"
                    icon="#cx-app1-favourite-default"
                  />
                  <icon
                    v-else-if="parent.is_favorite"
                    icon="#cx-app1-favourite-fill"
                  />
                  <icon v-else icon="#cx-app1-favourite" />
                </button>
              </span>
              <span class="label">
                <span class="highlight">{{ parent.name }}</span>
                <span class="inner-icons">
                  <span class="timezone-icon" v-if="parent.timezone_id">
                    <icon icon="#cx-app1-time" />
                  </span>
                  <span class="address-icon" v-if="hasLocation(parent)">
                    <icon icon="#cx-app1-location" />
                  </span>
                  <span class="map-icon" v-if="parent.map_id">
                    <icon icon="#cx-app1-5d-maps" />
                  </span>
                </span>
              </span>
              <span class="follow-up-icons">
                <router-link
                  class="follow-up-icon-item"
                  :to="{
                    name: 'r_levels',
                    params: {
                      level_id: idx === 0 ? '' : parentLevels[idx - 1].id
                    }
                  }"
                >
                  <svg-icon icon="outside"></svg-icon>
                </router-link>
              </span>
            </div>
          </li>
        </template>
        <template
          v-else-if="
            listOfLevels &&
              listOfLevels.length &&
              !routeParam &&
              mode === 'selection'
          "
          ><li
            class="clebex-item-section-item"
            v-for="lvl in listOfLevels"
            :key="lvl.id"
          >
            <div class="clebex-item-content-wrapper">
              <div class="checkbox tiny block alt">
                <input
                  :type="routeParam ? 'checkbox' : 'checkbox'"
                  :id="`level${lvl.id}`"
                  name="level"
                  :selected="lvl.id === level"
                  :value="lvl.id"
                  @change="selectLevel(lvl.id)"
                  :disabled="!canItem(lvl, 'delete')"
                />
                <label :for="`level${lvl.id}`">
                  <icon icon="#cx-app1-checkmark" />
                  <span class="label inner-text no-left-padding">
                    <span class="highlight">{{ lvl.name }}</span>
                    <span class="inner-icons">
                      <span class="timezone-icon" v-if="lvl.timezone_id">
                        <icon icon="#cx-app1-time" />
                      </span>
                      <span class="address-icon" v-if="hasLocation(lvl)">
                        <icon icon="#cx-app1-location" />
                      </span>
                      <span class="map-icon" v-if="lvl.map_id">
                        <icon icon="#cx-app1-5d-maps" />
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </li>
        </template>
        <template v-else>
          <li
            class="clebex-item-section-item"
            v-for="lvl in listOfLevels"
            :key="lvl.id"
            :class="{ disabled: !canItem(lvl, 'update') }"
          >
            <button
              class="clebex-item-content-wrapper"
              @click="viewSublevels(lvl.id)"
            >
              <span class="follow-up-icons">
                <button
                  class="follow-up-icon-item"
                  :class="{
                    default: lvl.is_default,
                    favorite: lvl.is_favorite && !lvl.is_default
                  }"
                  :disabled="!canItem(lvl, 'update')"
                  @click.stop="
                    !lvl.is_default
                      ? lvl.is_favorite
                        ? unsetFavorite(lvl.id)
                        : setFavorite(lvl.id)
                      : false
                  "
                >
                  <icon
                    v-if="lvl.is_default"
                    icon="#cx-app1-favourite-default"
                  />
                  <icon
                    v-else-if="lvl.is_favorite"
                    icon="#cx-app1-favourite-fill"
                  />
                  <icon v-else icon="#cx-app1-favourite" />
                </button>
              </span>
              <span class="label">
                <span class="highlight">{{ lvl.name }}</span>
                <span class="inner-icons">
                  <span class="timezone-icon" v-if="lvl.timezone_id">
                    <icon icon="#cx-app1-time" />
                  </span>
                  <span class="address-icon" v-if="hasLocation(lvl)">
                    <icon icon="#cx-app1-location" />
                  </span>
                  <span class="map-icon" v-if="lvl.map_id">
                    <icon icon="#cx-app1-5d-maps" />
                  </span>
                </span>
              </span>
              <span class="follow-up-icons">
                <button
                  class="follow-up-icon-item"
                  @click.stop="editLevel(lvl.id)"
                >
                  <icon icon="#cx-app1-information" />
                </button>
              </span>
            </button>
          </li>
        </template>
      </ul>

      <!-- levels section children or top level items -->
      <ul
        class="clebex-item-section pill"
        v-if="listOfLevels && listOfLevels.length && routeParam"
      >
        <li class="clebex-item-section-label-inner">
          <div class="clebex-label-content-wrapper">
            {{ displayLabelName("level", "level", "sub-levels") }}
          </div>
        </li>
        <template v-if="mode === 'selection'">
          <li
            class="clebex-item-section-item"
            v-for="lvl in listOfLevels"
            :key="lvl.id"
          >
            <div class="clebex-item-content-wrapper">
              <div class="checkbox tiny block alt">
                <input
                  :type="routeParam ? 'checkbox' : 'checkbox'"
                  :id="`level${lvl.id}`"
                  name="level"
                  :selected="lvl.id === level"
                  :value="lvl.id"
                  @change="selectLevel(lvl.id)"
                  :disabled="!canItem(lvl, 'delete')"
                />
                <label :for="`level${lvl.id}`">
                  <icon icon="#cx-app1-checkmark" />
                  <span class="label inner-text no-left-padding">
                    <span class="highlight">{{ lvl.name }}</span>
                    <span class="inner-icons">
                      <span class="timezone-icon" v-if="lvl.timezone_id">
                        <icon icon="#cx-app1-time" />
                      </span>
                      <span class="address-icon" v-if="hasLocation(lvl)">
                        <icon icon="#cx-app1-location" />
                      </span>
                      <span class="map-icon" v-if="lvl.map_id">
                        <icon icon="#cx-app1-5d-maps" />
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </li>
        </template>
        <template v-else>
          <li
            class="clebex-item-section-item"
            v-for="lvl in listOfLevels"
            :key="lvl.id"
            :class="{ disabled: !canItem(lvl, 'update') }"
          >
            <button
              class="clebex-item-content-wrapper"
              @click="viewSublevels(lvl.id)"
            >
              <span class="follow-up-icons">
                <button
                  class="follow-up-icon-item"
                  :class="{
                    default: lvl.is_default,
                    favorite: lvl.is_favorite && !lvl.is_default
                  }"
                  :disabled="!canItem(lvl, 'update')"
                  @click.stop="
                    !lvl.is_default
                      ? lvl.is_favorite
                        ? unsetFavorite(lvl.id)
                        : setFavorite(lvl.id)
                      : false
                  "
                >
                  <icon
                    v-if="lvl.is_default"
                    icon="#cx-app1-favourite-default"
                  />
                  <icon
                    v-else-if="lvl.is_favorite"
                    icon="#cx-app1-favourite-fill"
                  />
                  <icon v-else icon="#cx-app1-favourite" />
                </button>
              </span>
              <span class="label">
                <span class="highlight">{{ lvl.name }}</span>
                <span class="inner-icons">
                  <span class="timezone-icon" v-if="lvl.timezone_id">
                    <icon icon="#cx-app1-time" />
                  </span>
                  <span class="address-icon" v-if="hasLocation(lvl)">
                    <icon icon="#cx-app1-location" />
                  </span>
                  <span class="map-icon" v-if="lvl.map_id">
                    <icon icon="#cx-app1-5d-maps" />
                  </span>
                </span>
              </span>
              <span class="follow-up-icons">
                <button
                  class="follow-up-icon-item"
                  @click.stop="editLevel(lvl.id)"
                >
                  <icon icon="#cx-app1-information" />
                </button>
              </span>
            </button>
          </li>
        </template>
      </ul>
    </template>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              @click="setMode(mode === 'selection' ? null : 'selection')"
              :class="{ active: mode === 'selection' }"
            >
              {{ displayLabelName("level", "level", "select") }}
            </button>
          </li>
          <li class="action" :style="deleteButtonStyle">
            <button class="action-btn" @click="show = true">
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                selectedLevels.length +
                "/" +
                (listOfLevels ? listOfLevels.length : 0) +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="
        displayLabelName('level', 'level', 'delete-confirm-action')
      "
      :cancel-button-label="
        displayLabelName('level', 'level', 'delete-cancel-action')
      "
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("level", "level", "delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("level", "level", "delete-action-text") }}
        <br />
        {{ displayLabelName("level", "level", "delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { errorHandler } from "@/services/error-handler";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
// import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "Levels",
  mixins: [helpOnlineMixin],
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
      mode: null,
      selectedLevels: [],
      search: null,
      favoritesActive: null,
      showSearchResults: null,
      helpSlug: "levels"
    };
  },
  created() {
    this.setPaginationData(null);
    this.listOfLevels = [];
    this.getLevelData();
  },
  computed: {
    ...mapState("level", ["levels", "level", "favoriteLevels"]),
    ...mapGetters("level", ["parentLevels"]),
    routeParam() {
      const { level_id } = this.$route.params;
      return level_id;
    },
    listOfLevels() {
      if (
        this.levels &&
        this.levels.data &&
        this.levels.data.length &&
        !this.processing
      ) {
        return this.levels.data;
      }
      return null;
    },
    deleteButtonStyle() {
      if (this.mode === "selection" && this.selectedLevels.length) {
        return {
          visibility: "visible"
        };
      }

      return {
        visibility: "hidden"
      };
    }
  },
  watch: {
    routeParam() {
      this.$store.commit("level/setLevelData", null, { root: true });
      this.listOfLevels = [];
      this.getLevelData();
    }
  },
  methods: {
    ...mapActions("level", [
      "getLevels",
      "getSublevels",
      "setSelectedLevel",
      "deleteLevel",
      "deleteLevels",
      "getLevel",
      "favoriteLevel",
      "unFavoriteLevel",
      "getLevels",
      "resetLevelsState"
    ]),
    ...mapActions("country", ["getCountries"]),
    ...mapActions("pagination", ["setPaginationData"]),
    getLevelData() {
      const query = this.searchQuery;
      const { level_id } = this.$route.params;
      if (query) {
        return this.getLevels({
          query,
          includes: ["all_parents", "default"],
          depth: "ALL"
        });
      }

      if (level_id) {
        this.getSublevels(level_id);
        this.getLevel(level_id);
      } else {
        this.getLevels({
          includes: ["default"]
        });
      }
    },
    selectLevel(id) {
      // if (this.routeParam) {
      const index = this.selectedLevels.findIndex(lvl => lvl === id);
      if (index === -1) {
        this.selectedLevels.push(id);
      } else {
        this.selectedLevels.splice(index, 1);
      }
      // } else {
      //   this.selectedLevels = [id];
      // }
    },
    editLevel(id) {
      let level;
      if (typeof id === "object") {
        level = [id];
      } else {
        level = this.levels.data.filter(item => item.id === id);
      }
      this.setSelectedLevel(level);

      this.$router.push({
        name: "r_edit-level",
        params: this.$route.params
      });
    },
    async startDelete() {
      const { level_id } = this.$route.params;
      this.processing = true;
      this.setPaginationData(null);

      if (level_id && !this.favoritesActive) {
        this.deleteLevels({
          level_id,
          sublevels: this.selectedLevels
        }).finally(() => {
          this.prfocessing = false;
          this.setSelectedLevel(null);
          this.selectedLevels = [];
          this.$nextTick(() => {
            this.getSublevels(level_id);
            this.getLevel(level_id);
          });
        });
      } else {
        for (var i = 0; i < this.selectedLevels.length; i++) {
          await this.deleteLevel(this.selectedLevels[i]);
        }

        this.processing = false;
        this.setSelectedLevel(null);
        this.selectedLevels = [];
        this.$nextTick(() => {
          this.getLevels({
            includes: ["default"]
          });
        });
      }

      this.mode = null;
    },
    viewSublevels(id) {
      // reset selection
      this.setSelectedLevel(null);

      // load sublevels
      this.$router.push({
        name: "r_levels",
        params: {
          level_id: id
        }
      });
    },
    setMode(mode) {
      this.selectedLevels = [];
      this.mode = mode;
    },
    toggleSearch() {
      this.search = !this.search;
    },
    hasLocation(level) {
      return (
        level.address_line_1 ||
        level.address_line_2 ||
        level.city ||
        level["zip"] ||
        level.state
      );
    },
    setFavorite(id) {
      this.favoriteLevel(id)
        .then(() => {
          this.getLevelData();
        })
        .catch(error => {
          errorHandler(error.response);
        });
    },
    unsetFavorite(id) {
      this.unFavoriteLevel(id)
        .then(() => {
          this.getLevelData();
        })
        .catch(error => {
          errorHandler(error.response);
        });
    },
    openFavorites() {
      this.getLevels({
        depth: "ALL",
        favorites: 1,
        includes: ["all_parents", "default"]
      });
      this.selectedLevels = [];
      this.favoritesActive = true;
    },
    openLevels() {
      this.selectedLevels = [];
      this.favoritesActive = false;
      this.getLevelData();
    },
    submitSearch() {
      this.showSearchResults = !!this.searchQuery;
      this.$store.commit("level/setLevelsState", null, { root: true });
      this.openLevels();
    }
  },
  beforeUnmount() {
    this.resetLevelsState();
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
